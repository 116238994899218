<template lang="html">
	<article class="step">
		<div class="grid">
			<div class="grid__col grid__col--6 grid">
				<div class="grid__col grid__col--12">
					<input
						type="text"
						v-model="details.name"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.name`)" />
				</div>
				<div class="grid__col grid__col--12">
					<input
						type="text"
						v-model="details.organizer"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.organizer`)" />
				</div>
				<div class="grid__col grid__col--6">
					<date-picker
						v-model="details.dates"
						:placeholder="$t(`${sector}.details.placeholder.dates`)"
						:not-after="new Date()"
						:disabled="!isEditable"
						range />
				</div>
				<div class="grid__col grid__col--6">
					<dropdown-select
						:options="LOCATIONS"
						v-model="details.location"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.location`)" />
				</div>
				<div class="grid__col grid__col--12">
					<dropdown-select
						:options="TYPES"
						v-model="details.type"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.type`)" />
				</div>
				<div class="grid__col grid__col--4">
					<input
						type="number"
						v-model="details.partakers"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.partakers`)"
						min="0" />
				</div>
				<div class="grid__col grid__col--4">
					<input
						type="number"
						v-model="details.spectators"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.spectators`)"
						min="0" />
				</div>
			</div>
			<div class="grid__col grid__col--6">
				<word-counter
					v-model="details.observations"
					:placeholder="$t(`${sector}.details.placeholder.observations`)"
					:disabled="!isEditable"
					:max="500" />
			</div>
		</div>
	</article>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import StepMixin from '@/mixins/StepMixin';
import DropdownSelect from '@/components/DropdownSelect.vue';
import DatePicker from '@/components/DatePicker.vue';
import WordCounter from '@/components/WordCounter.vue';
import { LOCATIONS, TYPES } from '@/assets/constants/events';

// Input validation
const isValid = (details) => {
	const { name, organizer, dates, location, type } = details;
	return !!name && !!organizer && !!dates && !!location && !!type;
};

export default {
	name: 'StepDetails',
	mixins: [StepMixin],
	components: { DropdownSelect, DatePicker, WordCounter },
	data() {
		return {
			LOCATIONS,
			TYPES,
		};
	},
	computed: {
		...mapState(['report']),
		details: {
			get() { return this.report.details; },
			set(details) { this.setDetails(details); },
		},
		isEditable() { return this.report.editable; },
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			handler(details) { this.$emit('validate', isValid(details)); },
		},
	},
	methods: mapMutations(['setDetails']),
};
</script>
