import LOCATIONS from './locations';

const TYPES = [
	{ label: 'Cursa de muntanya' },
	{ label: 'Competició desquí­ alpí­ / muntanya' },
	{ label: 'Cursa de bicicleta de carretera' },
	{ label: 'Competició de BTT' },
	{ label: 'Carrera de vehicles a motor' },
	{ label: 'Triatló/Duatló' },
	{ label: 'Competició indoor' },
	{ label: 'Congrés / jornada / conferència' },
	{ label: 'Exposició' },
	{ label: 'Fira' },
	{ label: 'Teatre' },
	{ label: 'Cinema' },
	{ label: 'Concerts / dansa / circ' },
	{ label: 'Curs' },
	{ label: 'Inauguració' },
	{ label: 'Presentació oficial' },
	{ label: 'Festes diverses' },
	{ label: 'Altres' },
];

export { TYPES, LOCATIONS };
