<template lang="html">
	<div class="datepicker">
		<input
			type="text"
			:value="dateString"
			:placeholder="placeholder"
			:disabled="disabled"
			readonly />
		<div class="datepicker__calendar calendar" v-if="!disabled">
			<header>
				<button @click="varyMonth(-1)">&lt;</button>
				<span>{{ month }} {{ year }}</span>
				<button @click="varyMonth(1)">&gt;</button>
			</header>
			<ul class="calendar__weekdays">
				<li v-for="day in days" :key="day">{{ day }}</li>
			</ul>
			<ul class="calendar__days">
				<li
					v-for="day in monthDays"
					:key="day.date.toString()"
					:class="dayClass(day)"
					@click="selectDay(day)">
					{{ day.day }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { shortDate } from '@/utils';

const sameDate = (date, otherDate) => {
	if (!date || !otherDate) return false;
	return shortDate(date) === shortDate(otherDate);
};

const dateBetweenDates = (date, dateLimit1, dateLimit2) => {
	if (!dateLimit1 || !dateLimit2) return false;
	const limitTime1 = dateLimit1.valueOf();
	const limitTime2 = dateLimit2.valueOf();
	const dateTime = date.valueOf();
	return (dateTime > limitTime1 && dateTime < limitTime2)
			|| (dateTime > limitTime2 && dateTime < limitTime1);
};

export default {
	name: 'DatePicker',
	data() {
		return {
			today: new Date(),
			cursor: undefined,
			rangeFirstDate: undefined,
		};
	},
	props: {
		value: undefined,
		placeholder: {
			type: String,
			default() { return this.$t('global.default.datepicker.placeholder'); },
		},
		notBefore: { type: Date, default: undefined },
		notAfter: { type: Date, default: undefined },
		range: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	},
	computed: {
		dates: {
			get() {
				if (this.value) return this.value.map((date) => new Date(date));
				return this.range ? [undefined, undefined] : undefined;
			},
			set(dates) { this.$emit('input', dates); },
		},
		year() { return this.cursor.getFullYear(); },
		month() { return this.$t(`calendar.month.long[${this.cursor.getMonth()}]`); },
		days() { return this.$t('calendar.weekday.short'); },
		monthDays() {
			const begin = new Date(this.cursor.getFullYear(), this.cursor.getMonth(), 1);
			const end = new Date(this.cursor.getFullYear(), this.cursor.getMonth() + 1, 0);
			const firstMonday = new Date(begin);
			firstMonday.setDate(begin.getDate() - (begin.getDay() || 7) + 1);
			const lastSunday = new Date(end);
			lastSunday.setDate(end.getDate() + (end.getDay() ? 8 - end.getDay() : 1));
			const monthDays = [];
			const date = new Date(firstMonday);
			while (date < lastSunday) {
				monthDays.push({
					blurred: date.getMonth() !== this.cursor.getMonth(),
					disabled: (this.notBefore && date < this.notBefore)
						|| (this.notAfter && date > this.notAfter),
					date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
					day: date.getDate(),
				});
				date.setDate(date.getDate() + 1);
			}
			return monthDays;
		},
		dateString() {
			if (!this.value) return '';
			return this.range
				? `${shortDate(this.dates[0])} ~ ${shortDate(this.dates[1])}`
				: shortDate(this.dates);
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(value) { this.cursor = (value && new Date(value[0])) || new Date(); },
		},
	},
	methods: {
		dayClass({ disabled, blurred, date }) {
			const [min, max] = this.range ? this.dates : [this.dates, this.dates];
			return ['calendar__day', {
				'calendar__day--disabled': disabled,
				'calendar__day--blurred': blurred,
				'calendar__day--selected': sameDate(date, min) || sameDate(date, max),
				'calendar__day--between-selected': dateBetweenDates(date, min, max),
				'calendar__day--today': sameDate(date, this.today),
			}];
		},
		selectDay({ disabled, date }) {
			if (disabled) return;
			if (this.range) {
				const dates = this.rangeFirstDate ? [this.rangeFirstDate, date] : [date, date];
				this.dates = dates.sort((a, b) => b.valueOf() < a.valueOf());
				this.rangeFirstDate = this.rangeFirstDate ? undefined : date;
			} else this.dates = date;
		},
		varyMonth(months) {
			this.cursor = new Date(
				this.cursor.getFullYear(),
				this.cursor.getMonth() + months,
				15,
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.datepicker {
	display: inline-block;
	position: relative;
	font-size: 1em;

	input {
		margin: 0;
		width: 100%;
	}

	&__calendar {
		position: absolute;
		top: 100%;
		display: none;
		z-index: 1;

		&:hover,
    .datepicker:focus-within & { display: block; }
	}
}
</style>
